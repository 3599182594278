import request from '@/utils/request'


// 查询用户钱包列表
export function listAccount(query) {
  return request({
    url: '/biz/userAccount/list',
    method: 'get',
    params: query
  })
}

// 查询用户钱包分页
export function pageAccount(query) {
  return request({
    url: '/biz/userAccount/page',
    method: 'get',
    params: query
  })
}

// 查询用户钱包详细
export function getAccount(data) {
  return request({
    url: '/biz/userAccount/detail',
    method: 'get',
    params: data
  })
}

// 新增用户钱包
export function addAccount(data) {
  return request({
    url: '/biz/userAccount/add',
    method: 'post',
    data: data
  })
}

// 修改用户钱包
export function updateAccount(data) {
  return request({
    url: '/biz/userAccount/edit',
    method: 'post',
    data: data
  })
}

// 删除用户钱包
export function delAccount(data) {
  return request({
    url: '/biz/userAccount/delete',
    method: 'post',
    data: data
  })
}
// 增加钻石
export function addDiamond(data) {
  return request({
    url: '/biz/userAccount/addDiamond',
    method: 'post',
    data: data
  })
}
// 增加水晶
export function addCrystal(data) {
  return request({
    url: '/biz/userAccount/addCrystal',
    method: 'post',
    data: data
  })
}
