<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="id" prop="id" style="display: none" >
        <a-input v-model="form.id" placeholder="请输入"  disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="用户ID"   >
       {{form.userNo}}
      </a-form-model-item>
      <a-form-model-item label="用户昵称"   >
        {{form.userName}}
      </a-form-model-item>
      <a-form-model-item label="持卡人姓名"   >
        {{form.bankCardHolder}}
      </a-form-model-item>
      <a-form-model-item label="银行名称"   >
        {{form.bankName}}
      </a-form-model-item>
      <a-form-model-item label="银行卡号"   >
        {{form.bankCardNo}}
      </a-form-model-item>
      <a-form-model-item label="开户行"   >
        {{form.bankOpen}}
      </a-form-model-item>

      <a-form-model-item label="余额"  >
       {{form.money}}
      </a-form-model-item>
<!--      <a-form-model-item label="增加钻石数"   >-->
<!--        <a-input-number :min="1" :max="99999999" v-model="form.changeMoney" style="width: 180px;" placeholder="请输入增加钻石数"  />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            确定-->
<!--          </a-button>-->
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { addDiamond } from '@/api/biz/account'

export default {
  name: 'AddDiamond',
  props: {

  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id:null,
        nickname: null,
        userNo: null,
        reason:null,
        money:null,
        changeMoney:null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        changeMoney: [
          { required: true, message: '增加钻石数不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id:null,
        nickname: null,
        userNo: null,
        reason:null, money:null,changeMoney:null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      this.form=JSON.parse(JSON.stringify(row))
        this.open = true
        this.formTitle = '详情'
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            addDiamond(this.form).then(response => {
              this.$message.success(
                '操作成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
