<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户昵称" prop="userName">
                <a-input v-model="queryParam.userName" placeholder="请输入用户昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否绑定银行卡" prop="bankIsBind">
                <a-select placeholder="请选择是否绑定银行卡" v-model="queryParam.bankIsBind" style="width: 100%" allow-clear>
                  <a-select-option :value="0">
                    否
                  </a-select-option>
                  <a-select-option :value="1">
                    是
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <!-- 增加修改  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
      <create-form
        ref="createForm"
        :aliIsBindOptions="dict.type.sys_yes_no"
        :wxIsBindOptions="dict.type.sys_yes_no"
        :bankIsBindOptions="dict.type.sys_yes_no"
        @ok="getList"
      />
      <add-diamond ref="addDiamond" @ok="getList" />
      <add-crystal ref="addCrystal" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="aliIsBind" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no']" :value="record.aliIsBind"/>
        </span>
        <span slot="wxIsBind" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no']" :value="record.wxIsBind"/>
        </span>
        <span slot="bankIsBind" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no']" :value="record.bankIsBind"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.addDiamond.handleUpdate(record, undefined)" v-hasPermi="['biz:account:edit']" >
            <a-icon type="plus-circle" /> 详情
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['biz:account:edit']" />-->
<!--          <a @click="$refs.addCrystal.handleUpdate(record, undefined)" v-hasPermi="['biz:account:edit']" >-->
<!--            <a-icon type="plus-circle" /> 水晶-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['biz:account:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['biz:account:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAccount,listAccount, delAccount } from '@/api/biz/account'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import AddDiamond from '@/views/biz/account/modules/AddDiamond.vue'
import AddCrystal from '@/views/biz/account/modules/AddCrystal.vue'

export default {
  name: 'Account',
  components: {
    AddCrystal,
    AddDiamond,
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['sys_yes_no', 'sys_yes_no', 'sys_yes_no'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        money: null,
        crystalMoney: null,
        inviteMoney: null,
        freezeMoney: null,
        payPassword: null,
        salt: null,
        aliName: null,
        aliAccountNo: null,
        aliQrCode: null,
        aliIsBind: null,
        wxName: null,
        wxAccountNo: null,
        wxQrCode: null,
        wxIsBind: null,
        bankCardHolder: null,
        bankName: null,
        bankCardNo: null,
        bankOpen: null,
        bankIsBind: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 50,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '用户ID',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '余额',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '可提现金额',
          dataIndex: 'crystalMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否绑定银行卡',
          dataIndex: 'bankIsBind',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 1){
              return "是"
            }else{
              return "否"
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
        // {
        //   title: '持卡人姓名',
        //   dataIndex: 'bankCardHolder',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '银行名称',
        //   dataIndex: 'bankName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '银行卡号',
        //   dataIndex: 'bankCardNo',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '开户行',
        //   dataIndex: 'bankOpen',
        //   ellipsis: true,
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户钱包列表 */
    getList () {
      this.loading = true
     pageAccount(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        money: undefined,
        crystalMoney: undefined,
        inviteMoney: undefined,
        freezeMoney: undefined,
        payPassword: undefined,
        salt: undefined,
        aliName: undefined,
        aliAccountNo: undefined,
        aliQrCode: undefined,
        aliIsBind: undefined,
        wxName: undefined,
        wxAccountNo: undefined,
        wxQrCode: undefined,
        wxIsBind: undefined,
        bankCardHolder: undefined,
        bankName: undefined,
        bankCardNo: undefined,
        bankOpen: undefined,
        bankIsBind: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAccount(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/userAccount/export', {
            ...that.queryParam
          }, `用户钱包_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
