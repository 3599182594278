<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="余额" prop="money" >
        <a-input v-model="form.money" placeholder="请输入余额" />
      </a-form-model-item>
      <a-form-model-item label="可提现金额" prop="crystalMoney" >
        <a-input v-model="form.crystalMoney" placeholder="请输入可提现金额" />
      </a-form-model-item>
      <a-form-model-item label="邀请收益" prop="inviteMoney" >
        <a-input v-model="form.inviteMoney" placeholder="请输入邀请收益" />
      </a-form-model-item>
      <a-form-model-item label="冻结金额" prop="freezeMoney" >
        <a-input v-model="form.freezeMoney" placeholder="请输入冻结金额" />
      </a-form-model-item>
      <a-form-model-item label="支付密码" prop="payPassword" >
        <a-input v-model="form.payPassword" placeholder="请输入支付密码" />
      </a-form-model-item>
      <a-form-model-item label="密钥" prop="salt" >
        <a-input v-model="form.salt" placeholder="请输入密钥" />
      </a-form-model-item>
      <a-form-model-item label="支付宝认证姓名" prop="aliName" >
        <a-input v-model="form.aliName" placeholder="请输入支付宝认证姓名" />
      </a-form-model-item>
      <a-form-model-item label="支付宝账号" prop="aliAccountNo" >
        <a-input v-model="form.aliAccountNo" placeholder="请输入支付宝账号" />
      </a-form-model-item>
      <a-form-model-item label="支付宝收款二维码" prop="aliQrCode" >
        <a-input v-model="form.aliQrCode" placeholder="请输入支付宝收款二维码" />
      </a-form-model-item>
      <a-form-model-item label="是否绑定支付宝0未绑定1绑定" prop="aliIsBind" >
        <a-radio-group v-model="form.aliIsBind" button-style="solid">
          <a-radio-button v-for="(d, index) in aliIsBindOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="微信认证姓名" prop="wxName" >
        <a-input v-model="form.wxName" placeholder="请输入微信认证姓名" />
      </a-form-model-item>
      <a-form-model-item label="微信账号(openId)" prop="wxAccountNo" >
        <a-input v-model="form.wxAccountNo" placeholder="请输入微信账号(openId)" />
      </a-form-model-item>
      <a-form-model-item label="微信收款二维码" prop="wxQrCode" >
        <a-input v-model="form.wxQrCode" placeholder="请输入微信收款二维码" />
      </a-form-model-item>
      <a-form-model-item label="是否绑定微信0未绑定1绑定" prop="wxIsBind" >
        <a-radio-group v-model="form.wxIsBind" button-style="solid">
          <a-radio-button v-for="(d, index) in wxIsBindOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="持卡人姓名" prop="bankCardHolder" >
        <a-input v-model="form.bankCardHolder" placeholder="请输入持卡人姓名" />
      </a-form-model-item>
      <a-form-model-item label="银行名称" prop="bankName" >
        <a-input v-model="form.bankName" placeholder="请输入银行名称" />
      </a-form-model-item>
      <a-form-model-item label="银行卡号" prop="bankCardNo" >
        <a-input v-model="form.bankCardNo" placeholder="请输入银行卡号" />
      </a-form-model-item>
      <a-form-model-item label="开户行" prop="bankOpen" >
        <a-input v-model="form.bankOpen" placeholder="请输入开户行" />
      </a-form-model-item>
      <a-form-model-item label="是否绑定银行卡0未绑定1绑定" prop="bankIsBind" >
        <a-radio-group v-model="form.bankIsBind" button-style="solid">
          <a-radio-button v-for="(d, index) in bankIsBindOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAccount, addAccount, updateAccount } from '@/api/biz/account'

export default {
  name: 'CreateForm',
  props: {
    aliIsBindOptions: {
      type: Array,
      required: true
    },
    wxIsBindOptions: {
      type: Array,
      required: true
    },
    bankIsBindOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        money: null,

        crystalMoney: null,

        inviteMoney: null,

        freezeMoney: null,

        payPassword: null,

        salt: null,

        aliName: null,

        aliAccountNo: null,

        aliQrCode: null,

        aliIsBind: 0,

        wxName: null,

        wxAccountNo: null,

        wxQrCode: null,

        wxIsBind: 0,

        bankCardHolder: null,

        bankName: null,

        bankCardNo: null,

        bankOpen: null,

        bankIsBind: 0,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        money: [
          { required: true, message: '余额不能为空', trigger: 'blur' }
        ],
        crystalMoney: [
          { required: true, message: '可提现金额不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        money: null,
        crystalMoney: null,
        inviteMoney: null,
        freezeMoney: null,
        payPassword: null,
        salt: null,
        aliName: null,
        aliAccountNo: null,
        aliQrCode: null,
        aliIsBind: 0,
        wxName: null,
        wxAccountNo: null,
        wxQrCode: null,
        wxIsBind: 0,
        bankCardHolder: null,
        bankName: null,
        bankCardNo: null,
        bankOpen: null,
        bankIsBind: 0,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAccount({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAccount(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAccount(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
